import './loadingScreen.scss';

// sketch:type TO sketchType
// xmlns:xlink TO xmlnsXlink
// xlink:href  TO xlinkHref

export default function LoadingScreen() {
    return (
	<div class="loadScreen">
	  <div class="lumiText">
		WELCOME
	  </div>
	</div>
    );
  }
  